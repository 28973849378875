import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function InstitutionalDonors() {
  const data = useStaticQuery(graphql`
    query InstitutionalDonorsQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-donors"}}) {
        frontmatter {
          institutionalDonors {
            website
            donorImage
          }
        }
      }
    }
  `);
  const {institutionalDonors} = data.markdownRemark.frontmatter;

  return (
    <div className="bg-[url('../img/backgrounds/background5.jpg')]">
      <p className="text-center text-3xl p-10">Institutional Donors</p>
      <div className="flex flex-row flex-wrap">
        {institutionalDonors.map(({website, donorImage}, index) => (
          <div key={index} className="lg:w-1/4 md:w-1/3 sm:w-1/2 p-5">
            <a href={website || "#"} className="bg-white rounded-lg text-xs text-blue-800 hover:text-blue-400">
              <img src={donorImage} className="object-contain rounded-lg"/>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InstitutionalDonors;
