import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function MonthlyDonors() {
  const data = useStaticQuery(graphql`
    query MonthlyDonorsQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-donors"}}) {
        frontmatter {
          monthlyDonors {
            title
            donorsList
          }
        }
      }
    }
  `);

  const monthlyDonors = data.markdownRemark.frontmatter.monthlyDonors.map(donorsData => ({
    ...donorsData,
    donorsList: (donorsData.donorsList || "").split("\n").map(x => x.trim()).filter(x => !!x)
  }));

  return (
    <div className="p-10">
      <p className="text-center text-2xl font-bold mb-5">
        Monthly Donors
      </p>
      <p className="text-center text-md mb-5">
        Thanking everyone for their generous contributions
      </p>
      <hr/>
      <div className="mb-5"/>
      {monthlyDonors.map(({title, donorsList}, index) => (
        <div key={index} className="mb-5">
          <p className="text-center text-xl font-bold mb-5">
            {title}
          </p>
          <div className="grid sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 mb-5">
            {donorsList.map(((donorName, donorIndex) => (
              <div key={donorIndex} className="flex-1 p-2">
                {donorName}
              </div>
            )))}
          </div>
          <hr/>
        </div>
      ))}
    </div>
  );
}

export default MonthlyDonors;
