import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import InstitutionalDonors from "./institutionalDonors";
import MonthlyDonors from "./monthlyDonors";

const OurDonorsPage = (props) => {

  const data = useStaticQuery(graphql`
    query OurDonorsPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-donors"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/donate" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <InstitutionalDonors/>
      <MonthlyDonors/>

      <HelpMethodsSection/>
    </Layout>
  );
};

OurDonorsPage.propTypes = {};

export default OurDonorsPage;
